import React from 'react';
import styles from '../styles/Navbar.module.css';

function Navbar() {
  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <img
          src={`${process.env.PUBLIC_URL}/images/edt-partners-logo.png`}
          alt="EDT Partners"
        />
      </div>
      <div className={styles.links}>
        <a
          href="https://www.edtpartners.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          About Us
        </a>
        <a href="/register">Register</a>
      </div>
    </nav>
  );
}

export default Navbar;

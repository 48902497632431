const awsConfig = {
  Auth: {
    Cognito: {
      region: 'eu-central-1',
      userPoolId: 'eu-central-1_M7ozPiFZd',
      userPoolClientId: '14iqjjibaktco99ahhkechb5ab',
    },
  },
};

export default awsConfig;

import React from 'react';
import styles from '../styles/Home.module.css';

function Home() {
  return (
    <div className={styles.hero}>
      <div className={styles.content}>
        <h1>The Next Generation Recommendation Engine</h1>
        <p>
          Experience the power of intelligent recommendations tailored to your
          needs.
        </p>
        <button
          className={styles.registerButton}
          onClick={() => (window.location.href = '/register')}
        >
          Register Now
        </button>
      </div>
      <div className={styles.image}>
        <img
          src={`${process.env.PUBLIC_URL}/images/robot.png`}
          alt="Recommendation Engine"
        />
      </div>
    </div>
  );
}

export default Home;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  InputAdornment,
  CircularProgress,
  Grid,
} from '@mui/material';
import {
  AccountCircle,
  Business,
  Email,
  LocationCity,
  Phone,
  Public,
} from '@mui/icons-material';
import { signUp } from '@aws-amplify/auth';

const schema = yup.object().shape({
  companyName: yup.string().required('Company Name is required'),
  contactPerson: yup.string().required('Contact Person is required'),
  contactPhone: yup
    .string()
    .matches(/^[0-9]+$/, 'Contact Phone must be a number')
    .required('Contact Phone is required'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  countryName: yup.string().required('Country Name is required'),
  cityName: yup.string().required('City Name is required'),
});

function RegisterForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data) => {
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      await signUp({
        username: data.email,
        password: 'TemporaryPassword123!',
        attributes: {
          email: data.email,
          'custom:companyName': data.companyName,
          'custom:contactPerson': data.contactPerson,
          'custom:contactPhone': data.contactPhone,
          'custom:countryName': data.countryName,
          'custom:cityName': data.cityName,
        },
      });

      setSuccessMessage('User registered successfully!');
    } catch (error) {
      console.error('Error registering user:', error);
      setErrorMessage(error.message || 'Error registering user.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background.jpg)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: "'Inter', sans-serif",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            padding: { xs: 3, sm: 5 },
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            borderRadius: '16px',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/edt-partners-logo.png`}
              alt="EDT&Partners Logo"
              style={{ maxWidth: '250px' }}
            />
          </Box>
          <Typography
            variant="h6"
            component="h1"
            align="center"
            sx={{
              mb: 2,
              fontFamily: "'Inter', sans-serif",
              fontWeight: 500,
              color: '#555',
              letterSpacing: '0.5px',
            }}
          >
            Please enter your contact details
          </Typography>
          {successMessage && (
            <Typography
              variant="body1"
              align="center"
              sx={{
                mb: 3,
                color: 'green',
              }}
            >
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography
              variant="body1"
              align="center"
              sx={{
                mb: 3,
                color: 'red',
              }}
            >
              {errorMessage}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                  {...register('companyName')}
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Business />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Contact Person"
                  variant="outlined"
                  fullWidth
                  {...register('contactPerson')}
                  error={!!errors.contactPerson}
                  helperText={errors.contactPerson?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Contact Phone"
                  variant="outlined"
                  fullWidth
                  {...register('contactPhone')}
                  error={!!errors.contactPhone}
                  helperText={errors.contactPhone?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  {...register('email')}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Country Name"
                  variant="outlined"
                  fullWidth
                  {...register('countryName')}
                  error={!!errors.countryName}
                  helperText={errors.countryName?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Public />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City Name"
                  variant="outlined"
                  fullWidth
                  {...register('cityName')}
                  error={!!errors.cityName}
                  helperText={errors.cityName?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCity />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  disabled={loading}
                  sx={{
                    borderRadius: '8px',
                    padding: '12px 20px',
                    fontSize: '1rem',
                    backgroundColor: '#4285F4',
                    color: 'white',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#0056d4',
                    },
                  }}
                  startIcon={
                    loading && <CircularProgress size={20} color="inherit" />
                  }
                >
                  {loading ? 'Submitting...' : 'Register'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
}

export default RegisterForm;
